import { Field, FieldError } from 'react-jsonschema-form-validation';
import PropTypes from 'prop-types';

import {
	FormGroup,
	Input,
	Label,
} from 'reactstrap';

export const ContactFormGroupBoard = ({ disabled, value }) => (
	<FormGroup key="board">
		<Label className="mb-0 font-weight-bold">Direction filiale</Label>
		<Field
			component={Input}
			disabled={disabled}
			placeholder="Direction filiale (DS2C, Mobiliz, BPCE Assurances ...)"
			name="board"
			value={value}
		/>
		<FieldError name="board" />
	</FormGroup>
);

ContactFormGroupBoard.propTypes = {
	disabled: PropTypes.bool,
	value: PropTypes.string.isRequired,
};

ContactFormGroupBoard.defaultProps = {
	disabled: false,
};
