import { getAuth } from '../lib/authentication';
import { request } from '../lib/request';

const { REACT_APP_API_URL } = process.env;

const STATS_API_PATH = `${REACT_APP_API_URL}/stats`;

export const postView = () => request(`${STATS_API_PATH}/view`, {
	headers: { qui: getAuth() },
	method: 'POST',
});
