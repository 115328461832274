import { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from 'reactstrap';
import { translate } from '../../lib/translate';

export const ModalDelUser = ({
	handleDeleteUser,
	user,
	setShowModalDeleteUser,
}) => {
	const toggle = useCallback(() => {
		setShowModalDeleteUser(null);
	}, [setShowModalDeleteUser]);

	return (
		<Modal zIndex={2000} centered isOpen={user}>
			<ModalHeader toggle={toggle}>Suppression d&apos;utilisateur</ModalHeader>
			<ModalBody>
				Voulez vous vraiment supprimer l&apos;utilisateur suivant ? <br />
				Nom : <strong>{user.username}</strong>
				<br />
				Rôle : {translate[user.role]}
				<br /><br />
			</ModalBody>
			<ModalFooter>
				<Button color="link" className="text-dark" onClick={toggle}>
					Annuler
				</Button>{' '}
				<Button color="cecaz" className="ml-auto" onClick={handleDeleteUser}>
					Supprimer
				</Button>
			</ModalFooter>
		</Modal>
	);
};

ModalDelUser.propTypes = {
	handleDeleteUser: PropTypes.func.isRequired,
	setShowModalDeleteUser: PropTypes.func.isRequired,
	user: PropTypes.shape().isRequired,
};
