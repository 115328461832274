import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = ({ children, elementRef }) => {
	const { pathname } = useLocation();
	const element = elementRef ? elementRef.current : window;

	useEffect(() => {
		if (!element) return;
		element.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [pathname, element]);

	return children || null;
};
