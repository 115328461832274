/* eslint-disable no-confusing-arrow */
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import { QuoiColumn } from './QuoiColumn';
import { DomainColumn } from './DomainColumn';
import { UserContext } from '../context/UserContext';
import { enumRole } from '../lib/enumRole';

const translate = (col) => {
	const dico = {
		comments: 'commentaires',
		domain: 'domaine',
	};
	return dico[col] || col;
};

export const SimpleTable = ({
	className,
	columns,
	data,
	onClickCell,
	indexed,
}) => {
	const { user } = useContext(UserContext);
	const isAdmin = user?.role === enumRole.ADMIN;

	const renderLine = (colName, lineData) => {
		switch (colName) {
		case 'quoi':
			return <QuoiColumn className="m-0 p-0" quoi={lineData.quoi} />;
		case 'domain':
			return <DomainColumn className="list-unstyled m-0 p-0" domain={lineData.domain} />;
		case 'email':
			return (
				<a
					className="mailto"
					href={`mailto:${lineData.email}`}
					title="Envoyer un email"
					target="__blank"
				>
					{lineData.email}
				</a>
			);
		case 'qui':
			// eslint-disable-next-line no-case-declarations
			const regexTel = new RegExp(/((?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d){8}).*?/, 'gim');
			// eslint-disable-next-line no-case-declarations
			const telNormalized = lineData.qui.replaceAll('.', '').trim();
			if (telNormalized?.match(regexTel)) {
				return (
					<>
						{telNormalized?.split(regexTel).map((t) => (
							<>
								{
									t?.match(regexTel) ? (
										<a
											className="tel"
											// eslint-disable-next-line prefer-template
											href={'tel:' + t?.replaceAll(' ', '')?.replaceAll('.', '')}
											title="Appeler"
											target="__blank"
											key={t}
										>
											{t}
										</a>
									) : t
								}
							</>
						))}
					</>
				);
			}
			return lineData.qui;
		default:
			return lineData[colName];
		}
	};

	return (
		<Table className={className} bordered hover striped>
			<thead>
				<tr>
					{indexed && <th>#</th>}
					{columns.map((col) => (
						<th key={col}>{translate(col)}</th>
					))}
					{ isAdmin && <th>Actions</th> }
				</tr>
			</thead>
			<tbody>
				{data.map((d, i) => (
					<tr
						style={onClickCell ? { cursor: 'pointer' } : {}}
						onClick={(e) => onClickCell(d, e)}
						key={d.id || i}
					>
						{indexed && <td>{d.id}</td>}
						{columns.map((colName) => (
							<td key={`${colName}-${d.id || i}`} style={{ verticalAlign: 'middle' }}>
								{ renderLine(colName, d) }
							</td>
						))}
						{isAdmin && (
							<td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
								{/* <FontAwesomeIcon icon={faEye} /> */}
								<Link className="p-4 text-center custom-a" to={`/contact/${d.id}`}>
									<FontAwesomeIcon icon={faPen} />
								</Link>
							</td>
						)}
					</tr>
				))}
			</tbody>
		</Table>
	);
};

SimpleTable.propTypes = {
	className: PropTypes.string,
	columns: PropTypes.arrayOf(
		PropTypes.string,
	).isRequired,
	data: PropTypes.arrayOf(
		PropTypes.shape(),
	),
	indexed: PropTypes.bool,
	onClickCell: PropTypes.func,
};

SimpleTable.defaultProps = {
	className: '',
	data: [],
	indexed: false,
	onClickCell: null,
};
