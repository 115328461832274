import { encodeAuthBase64 } from '../lib/authentication';
import { request } from '../lib/request';

const { REACT_APP_API_URL } = process.env;

const AUTH_API_PATH = `${REACT_APP_API_URL}/authentification`;

export const fetchAuth = ({ username, password }) => {
	const cred = encodeAuthBase64({ username, password });
	const cred64 = btoa(`${cred.username}:${cred.password}`);

	return request(AUTH_API_PATH, {
		body: { login: 'true' },
		headers: { qui: `Basic ${cred64}` },
		method: 'POST',
	});
};
