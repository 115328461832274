import PropTypes from 'prop-types';
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from 'reactstrap';

export const ContactModalDel = ({
	contact,
	handleDeleteContact,
	toggle,
}) => (
	<Modal zIndex={2000} centered isOpen={contact}>
		<ModalHeader toggle={toggle}>Suppression du contact</ModalHeader>
		<ModalBody>
			Voulez vous vraiment supprimer ce contact ?
			<br /><br />
		</ModalBody>
		<ModalFooter>
			<Button color="secondary" onClick={toggle}>
				Annuler
			</Button>{' '}
			<Button color="cecaz" className="ml-auto" onClick={handleDeleteContact}>
				Supprimer
			</Button>
		</ModalFooter>
	</Modal>
);

ContactModalDel.propTypes = {
	handleDeleteContact: PropTypes.func.isRequired,
	toggle: PropTypes.func.isRequired,
	contact: PropTypes.shape().isRequired,
};
