import { useContext, useState } from 'react';
import {
	Collapse,
	Navbar,
	Nav,
	NavItem,
	NavLink,
	NavbarText,
	NavbarToggler,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { Image } from './Image';

import './Menu.scss';
import logoCECAZ from '../assets/images/quoiparqui-logo.png';
import logoCECAZP from '../assets/images/quoiparqui-logo.webp';

import { clearAuth } from '../lib/authentication';
import { UserContext } from '../context/UserContext';
import { enumRole } from '../lib/enumRole';

export const Menu = () => {
	const { user } = useContext(UserContext);
	const isAdmin = user?.role === enumRole.ADMIN;
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => setIsOpen(!isOpen);
	return (
		<div>
			<Navbar className="Menu py-0" light expand="md">
				<Link to="/" className="navbar-brand">
					<Image
						alt="Caisse d'Epargne Cote d'Azur"
						className=""
						src={logoCECAZ}
						webp={logoCECAZP}
						height={100}
					/>
				</Link>
				<NavbarToggler onClick={toggle} />
				<Collapse isOpen={isOpen} navbar>
					<Nav className="mr-auto" navbar>
						<NavItem>
							<NavLink tag={Link} to="/search" className="font-weight-bold text-uppercase">Rechercher</NavLink>
						</NavItem>
						{isAdmin && (
							<>
								<NavItem>
									<NavLink tag={Link} to="/users" className="font-weight-bold text-uppercase">Utilisateurs</NavLink>
								</NavItem>
								<NavItem>
									<NavLink tag={Link} to="/contact/create" className="font-weight-bold text-uppercase">créer un contact</NavLink>
								</NavItem>
							</>
						)}
					</Nav>
					<NavbarText className="pr-2">
						<span className="mr-4 d-none d-md-inline">
							<FontAwesomeIcon icon={faUser} />
							<span className="pl-1">{user?.username?.toLowerCase()}</span>
						</span>
						<Link className="custom-a" to="/login" onClick={clearAuth} title="déconnexion">
							<FontAwesomeIcon icon={faSignOutAlt} />
							<span className="d-md-none pl-1">Déconnexion</span>
						</Link>
					</NavbarText>
				</Collapse>
			</Navbar>
		</div>
	);
};
