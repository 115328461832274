import React from 'react';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import './QuoiTags.scss';
import { Input } from 'reactstrap';

const customRenderInput = ({
	onChange,
	value,
	addTag,
	...other
}) => (
	<Input type="text" onChange={onChange} value={value} {...other} />
);

export const QuoiTags = ({
	value,
	handleTagInputChange,
	name,
	disabled,
}) => {
	const tagsArray = value ? value.split('/') : [];
	return (
		<TagsInput
			className="QuoiTags react-tagsinput"
			disabled={disabled}
			inputProps={{ placeholder: 'Saissisez un ou des quoi (mot-clé)' }}
			onChange={(v) => handleTagInputChange(v, name)}
			renderInput={customRenderInput}
			value={tagsArray}
		/>
	);
};

QuoiTags.propTypes = {
	name: PropTypes.string,
	disabled: PropTypes.bool,
	value: PropTypes.string,
	handleTagInputChange: PropTypes.func,
};
QuoiTags.defaultProps = {
	name: '',
	value: '',
	disabled: false,
	handleTagInputChange: null,
};
