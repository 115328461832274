import { Suspense, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import {
	Switch,
	Route,
	Redirect,
	useLocation,
} from 'react-router-dom';

import { DefaultLayout } from './components/DefaultLayout';
import { PageAuth } from './pages/Auth';
import { PageError403 } from './pages/Error403';
import { PageSearch } from './pages/Search';
import { PageUsers } from './pages/Users';
import PrivateRoute from './lib/PrivateRoute';
import { enumRole } from './lib/enumRole';
import { PageContactEdit } from './pages/ContactEdit';
import { PageContactCreate } from './pages/ContactCreate';

function usePageViews() {
	const location = useLocation();
	const { trackPageView } = useMatomo();
	useEffect(() => {
		trackPageView({
			documentTitle: location.pathname,
		});
	}, [location, trackPageView]);
}

export const Routes = () => {
	usePageViews();
	return (
		<Suspense
			fallback={(
				<div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
					<div className="w-50 mx-auto">
						Loading
					</div>
				</div>
			)}
		>
			<Switch>
				<Route path="/login" component={PageAuth} />
				<Redirect exact from="/" to="/search" />
				<Route path={
					[
						'/contact/:id',
						'/contact/create',
						'/search',
						'/users',
						'/403',
					]
				}
				>
					<DefaultLayout>
						<Switch>
							<PrivateRoute exact path="/contact/create" component={PageContactCreate} role={[enumRole.ADMIN]} />
							<PrivateRoute exact path="/contact/:id" component={PageContactEdit} role={[enumRole.ADMIN]} />
							<PrivateRoute exact path="/search" component={PageSearch} role={[enumRole.USER, enumRole.ADMIN]} />
							<PrivateRoute exact path="/users" component={PageUsers} role={[enumRole.ADMIN]} />
							<Route exact path="/403" component={PageError403} />
						</Switch>
					</DefaultLayout>
				</Route>
			</Switch>
		</Suspense>
	);
};
