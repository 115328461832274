import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { Col, Container, Row } from 'reactstrap';

import { postView } from '../api/stats';
import { getAuth } from '../lib/authentication';
import { Menu } from './Menu';
import logoCECAZ from '../assets/images/logo-cecaz.webp';
import './DefaultLayout.scss';

const { REACT_APP_NAME } = process.env;

export const DefaultLayout = ({ children }) => {
	const { mutate } = useMutation(postView);

	useEffect(() => {
		const auth = getAuth();
		if (auth) {
			mutate();
		}
	}, [mutate]);

	return (
		<div className="app-wrapper DefaultLayout">
			<header className="app-header">
				<Menu />
			</header>
			<Container className="app-main d-flex" fluid>
				{children}
			</Container>
			<footer className="text-center text-white footer bg-light w-100">
				<Container fluid>
					<Row className="py-4">
						<Col xs="12" className="d-flex flex-column justify-content-between align-items-center my-0 text-align-right">
							<img
								className="my-2"
								alt="logo CECAZ"
								src={logoCECAZ}
								width="180"
							/>
						</Col>
					</Row>
					<Row className="bg-dark py-2">
						<Col xs="12">
							<span className="d-block copy">
								&copy;
								{(new Date()).getFullYear()}
								{' '}
								{REACT_APP_NAME}
								{' '}
								v
								{process.env.REACT_APP_VERSION}
							</span>
						</Col>
					</Row>
				</Container>
			</footer>
		</div>
	);
};

DefaultLayout.propTypes = {
	children: PropTypes.node,
};

DefaultLayout.defaultProps = {
	children: null,
};
