import { getAuth } from '../lib/authentication';
import { request } from '../lib/request';

const { REACT_APP_API_URL } = process.env;

const CONTACT_API_PATH = `${REACT_APP_API_URL}/contact`;

export const fetchAll = () => request(CONTACT_API_PATH, {
	headers: { qui: getAuth() },
});

export const fetchById = ({ queryKey: [, { id }] }) => request(`${CONTACT_API_PATH}/${id}`, {
	headers: { qui: getAuth() },
});

export const contactCreate = (data) => request(CONTACT_API_PATH, {
	headers: { qui: getAuth() },
	method: 'POST',
	body: data,
});

export const contactUpdate = (data) => request(`${CONTACT_API_PATH}/${data.id}`, {
	headers: { qui: getAuth() },
	method: 'PUT',
	body: data,
});

export const contactDeleteById = (id) => request(`${CONTACT_API_PATH}/${id}`, {
	headers: { qui: getAuth() },
	method: 'DELETE',
});

export const contactRestoreById = (id) => request(`${CONTACT_API_PATH}/restore/${id}`, {
	headers: { qui: getAuth() },
	method: 'PUT',
});

export const fetchBySearch = (search) => request(`${CONTACT_API_PATH}/search`, {
	headers: { qui: getAuth() },
	method: 'POST',
	body: { search },
});
