import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const ButtonLoader = ({
	children,
	className,
	loading,
	type,
	...rest
}) => (
	<Button
		className={`ButtonLoader ${className}`}
		disabled={loading}
		type={type}
		{...rest}
	>
		{
			loading
				? <Icon icon={faSpinner} spin />
				: children
		}
	</Button>
);

ButtonLoader.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	loading: PropTypes.bool,
	type: PropTypes.string,
};

ButtonLoader.defaultProps = {
	className: '',
	loading: false,
	type: 'button',
};
