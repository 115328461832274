exports.searchPattern = '^[a-zA-Z0-9(). \u00C0-\u00FF]*$';
exports.searchPatternRegex = /^[a-zA-Z0-9(). \u00C0-\u00FF]*$/;

exports.searchSchema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		search: { type: 'string', sanitize: 'striptags', pattern: exports.searchPattern },
	},
	required: ['search'],
};
