import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';
import Form from 'react-jsonschema-form-validation';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import {
	Badge,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Container,
	Row,
	UncontrolledAlert,
} from 'reactstrap';

import { contactValidatorUpdate } from 'api/lib/validators/contact';
import {
	fetchById, contactUpdate, contactRestoreById, contactDeleteById,
} from '../api/contact';

import { ContactModalDel } from '../components/contact/ContactModalDel';
import {
	ContactFormGroupActivityManager,
	ContactFormGroupBoard,
	ContactFormGroupComment,
	ContactFormGroupDomain,
	ContactFormGroupQui,
	ContactFormGroupQuoi,
	ContactFormGroupUnit,
} from '../components/contact/formGroup';
import { CREATE_ACTION } from './ContactCreate';

import errorMessages from '../lib/Form/errorMessages';

import './Contact.scss';
import { ContactFormGroupEmail } from '../components/contact/formGroup/Email';

const schema = contactValidatorUpdate;

const initState = {
	domain: '',
	board: '',
	unit: '',
	activityManager: '',
	qui: '',
	email: '',
	quoi: '',
	comments: '',
};
export const PageContactEdit = () => {
	const location = useLocation();
	const match = useRouteMatch();
	const history = useHistory();

	const isJustCreated = location.state?.action === CREATE_ACTION;

	const [data, setData] = useState(initState);
	const [isDeleted, setIsDeleted] = useState(false);
	const [showModalDel, setShowModalDel] = useState(false);

	const toggle = () => {
		setShowModalDel(false);
	};

	const {
		isLoading: isLoadingFetch,
		isError: isErrorFetch,
		error: errorFetch,
		refetch,
	} = useQuery(
		['fetchById', { id: match.params.id }],
		fetchById,
		{
			retry: false,
			refetchOnWindowFocus: false,
			onSuccess: ({
				id,
				activityManager,
				board,
				comments,
				deleted,
				domain,
				email,
				qui,
				quoi,
				unit,
			}) => {
				setData((prev) => ({
					id: id || prev.id,
					activityManager: activityManager || prev.activityManager,
					board: board || prev.board,
					comments: comments || prev.comments,
					domain: domain || prev.domain,
					email: email || prev.email,
					qui: qui || prev.qui,
					quoi: quoi || prev.quoi,
					unit: unit || prev.unit,
				}));
				setIsDeleted(deleted);
			},
		},
	);

	const { mutate: mutateUpdate, isSuccess: isSuccessUpdate } = useMutation(contactUpdate, {
		onSuccess: () => {
			window.scrollTo(0, 0);
		},
	});

	const {
		mutate: mutateRestore,
		isSuccess: isSuccessRestore,
	} = useMutation(contactRestoreById, {
		onSuccess: () => {
			refetch();
			window.scrollTo(0, 0);
		},
	});

	const { mutate: mutateDelContact, isSuccess: isSuccessDelete } = useMutation(contactDeleteById, {
		onSuccess: () => {
			refetch();
			toggle();
			window.scrollTo(0, 0);
		},
	});

	const handleDeleteContact = useCallback(() => {
		mutateDelContact(data.id);
	}, [data.id, mutateDelContact]);

	const handleChange = (newData) => {
		setData(newData);
	};

	const handleSubmit = () => {
		mutateUpdate(data);
	};

	const handleClickCancel = () => {
		history.goBack();
	};

	const handleClickDelete = () => {
		setShowModalDel(true);
	};

	const handleClickRestore = useCallback(() => {
		mutateRestore(data.id);
	}, [data.id, mutateRestore]);

	const handleTagInputChange = (newValue, name) => {
		setData((prev) => ({
			...prev,
			[name]: newValue.join('/'),
		}));
	};
	return (
		<Form
			className="Contact w-100 py-4"
			data={data}
			errorMessages={errorMessages}
			onChange={handleChange}
			onSubmit={handleSubmit}
			schema={schema}
		>
			<Container className="h-100">
				{isJustCreated && (
					<UncontrolledAlert className="w-100" color="success">
						Le contact a été créé avec succès.
					</UncontrolledAlert>
				)}
				{isSuccessUpdate && (
					<UncontrolledAlert className="w-100" color="success">
						Le contact a été modifié avec succès.
					</UncontrolledAlert>
				)}
				{isSuccessDelete && (
					<UncontrolledAlert className="w-100" color="success">
						Le contact a été <strong>supprimé avec succès</strong>.<br />
						Il n&apos;apparaitra plus dans les résultats de recherche.
					</UncontrolledAlert>
				)}
				{isSuccessRestore && (
					<UncontrolledAlert className="w-100" color="success">
						Le contact a été <strong>restoré avec succès</strong>.<br />
					</UncontrolledAlert>
				)}
				{isErrorFetch && (
					<UncontrolledAlert className="w-100" color="danger">
						{errorFetch.message}
					</UncontrolledAlert>
				)}
				{isLoadingFetch
					? (
						<Row className="w-100 h-100 d-flex justify-content-center align-items-center">
							<FontAwesomeIcon icon={faSpinner} spin />
						</Row>
					) : (
						<Row>
							<Card className="w-100">
								<CardHeader className="d-flex justify-content-between">
									<h1 className="mb-0 text-dark">
										Modifier le contact n°{data.id}
									</h1>
									{isDeleted && (
										<Badge className="p-1 d-flex align-items-center justify-content-between">
											Supprimé
										</Badge>
									)}
								</CardHeader>
								<CardBody>
									<ContactFormGroupDomain
										value={data.domain}
										disabled={isDeleted}
									/>
									<ContactFormGroupQuoi
										value={data.quoi}
										disabled={isDeleted}
										handleTagInputChange={handleTagInputChange}
									/>
									<ContactFormGroupEmail
										value={data.email}
									/>
									<ContactFormGroupQui
										value={data.qui}
										disabled={isDeleted}
									/>
									<ContactFormGroupComment
										value={data.comments}
										disabled={isDeleted}
									/>
									<ContactFormGroupBoard
										value={data.board}
										disabled={isDeleted}
									/>
									<ContactFormGroupUnit
										value={data.unit}
										disabled={isDeleted}
									/>
									<ContactFormGroupActivityManager
										value={data.activityManager}
										disabled={isDeleted}
									/>
								</CardBody>
								<CardFooter className="d-flex justify-content-between">
									{isDeleted ? (
										<Button onClick={handleClickRestore} color="secondary">
											Restaurer le contact
										</Button>
									) : (
										<>
											<Button onClick={handleClickDelete} color="dark">
												Supprimer
											</Button>
											<div>
												<Button onClick={handleClickCancel} color="secondary" className="mr-2">
													Annuler
												</Button>
												<Button color="cecaz">
													Modifier
												</Button>
											</div>
										</>
									)}
								</CardFooter>
							</Card>
						</Row>
					)}
			</Container>
			{showModalDel && (
				<ContactModalDel
					contact={data}
					toggle={toggle}
					handleDeleteContact={handleDeleteContact}
				/>
			)}
		</Form>
	);
};
