export const LOCAL_STORAGE_KEY = 'user';

export const updateLocalStorageUser = (auth) => (
	localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(auth))
);

export const clearLocalStorageUser = () => (
	localStorage.removeItem(LOCAL_STORAGE_KEY)
);

export const getLocalStorageUser = () => (
	JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {}
);
