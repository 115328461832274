import {
	useCallback, useContext, useRef, useState,
} from 'react';
import {
	Alert,
	Badge,
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Collapse,
	Table,
	Col,
} from 'reactstrap';

import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useMutation, useQuery } from 'react-query';
import { UpdateUser } from './UpdateUser';
import { ModalDelUser } from './ModalDelUser';
import { deleteUser, fetchAllUser, updateUser } from '../../api/user';
import { errMessage } from '../../lib/messages';
import { enumRole } from '../../lib/enumRole';

import './ListUser.scss';
import { UserContext } from '../../context/UserContext';

const badgeColor = (role) => {
	switch (role) {
	case enumRole.ADMIN:
		return 'warning';
	case enumRole.USER:
		return 'secondary';
	default:
		return 'warning';
	}
};

export const ListUser = () => {
	const notifRef = useRef(null);
	const history = useHistory();
	const { user: userContext, updateContextUser } = useContext(UserContext);
	const [selectedUser, setSelectedUser] = useState(null);
	const [showModalDeleteUser, setShowModalDeleteUser] = useState(false);

	const handleClickEditUser = useCallback((u) => {
		if (selectedUser?.id === u.id) {
			setSelectedUser(null);
		} else {
			setSelectedUser(u);
		}
	}, [selectedUser]);

	// use query
	const {
		data: users,
		refetch: refetchUsers,
	} = useQuery('users', fetchAllUser, {
		retryDelay: 200,
		retry: 2,
		onError: (errorFetchAllUser) => {
			if (errorFetchAllUser && errorFetchAllUser?.response?.status === 401) {
				history.push('/login');
			}
		},
	});

	const {
		mutate: mutateUser,
		isSuccess,
		isError,
		error,
		data,
	} = useMutation(updateUser, {
		onSuccess: (d) => {
			if (d?.username === userContext?.username) {
				updateContextUser(d);
			}
			refetchUsers();
		},
	});

	const {
		mutate: mutateDelUser,
		isSuccess: isSuccessDelete,
		isError: isErrorDelUser,
		error: errorDelUser,
	} = useMutation(deleteUser, {
		onSuccess: async () => {
			await refetchUsers();
		},
	});

	const handleDeleteUser = useCallback(async () => {
		await mutateDelUser(showModalDeleteUser.id);
		setShowModalDeleteUser(null);
	}, [mutateDelUser, showModalDeleteUser]);

	const handleMutateUpdate = useCallback(async (userData) => {
		await mutateUser(userData);
		setSelectedUser(null);
		notifRef.current?.scrollIntoView(false);
	}, [mutateUser]);

	return (
		<Card className="ListUser">
			<CardHeader>
				<h2 className="m-0 text-dark text-uppercase">
					Liste des utilisateurs
				</h2>
			</CardHeader>
			<CardBody className="p-lg-5">
				<div ref={notifRef}>
					{isSuccessDelete && <Alert color="success">L&apos;utilisateur a bien été supprimé</Alert>}
					{isSuccess && <Alert color="success">L&apos;utilisateur {data.username} a bien été modifié</Alert>}
					{(isError || isErrorDelUser) && <Alert color="danger">{errMessage(error || errorDelUser)}</Alert>}
				</div>
				<Table striped bordered>
					<colgroup>
						<col span="1" style={{ width: '50%' }} />
						<col span="1" style={{ width: '25%' }} />
						<col span="1" style={{ width: '25%' }} />
					</colgroup>
					<thead>
						<tr>
							<th>Identifiant</th>
							<th>Rôle</th>
							<th>Actions</th>
						</tr>
					</thead>
					{
						users?.map((u) => (
							<tbody key={u.id}>
								<tr>
									<th scope="row">
										{u.username}
										{u.username === userContext.username && <small> - mon compte</small>}
									</th>
									<td>
										<div className="d-flex align-items-center justify-content-center">
											<Badge color={badgeColor(u.role)} pill className="px-2">
												{u.role}
											</Badge>
										</div>
									</td>
									<td>
										<div className="d-flex align-items-center justify-content-center">
											<Button
												className="py-1 px-2 mr-4"
												color="secondary"
												title="Modifier l'utilisateur"
												disabled={u.username === userContext.username}
												onClick={() => handleClickEditUser(u)}
											>
												<FontAwesomeIcon icon={faPencilAlt} />
											</Button>
											<Button
												className="py-1 px-2"
												color="danger"
												title="Supprimer l'utilisateur"
												disabled={u.username === userContext.username}
												onClick={() => setShowModalDeleteUser(u)}
											>
												<FontAwesomeIcon icon={faTrash} />
											</Button>
										</div>
									</td>
								</tr>
								{u.id === selectedUser?.id && (
									<Collapse isOpen={u.id === selectedUser?.id} tag="tr">
										<td colSpan="3">
											<Col xs="12" lg="5" className="mx-auto my-5">
												<UpdateUser mutateUser={handleMutateUpdate} user={selectedUser} />
											</Col>
										</td>
									</Collapse>
								)}
							</tbody>

						))
					}
				</Table>

				{/* <div className="pb-4" style={{ overflowY: 'auto', maxHeight: '600px' }}> */}
				{showModalDeleteUser && (
					<ModalDelUser
						handleDeleteUser={handleDeleteUser}
						setShowModalDeleteUser={setShowModalDeleteUser}
						user={showModalDeleteUser}
					/>
				)}
				{showModalDeleteUser && (
					<ModalDelUser
						handleDeleteUser={handleDeleteUser}
						setShowModalDeleteUser={setShowModalDeleteUser}
						user={showModalDeleteUser}
					/>
				)}
			</CardBody>
			<CardFooter>
				({users?.length || 0}) utilisateurs
			</CardFooter>
		</Card>
	);
};
