import PropTypes from 'prop-types';

export const DomainColumn = ({ className, domain }) => (
	<ul className={className}>
		{
			domain.split('/').map((k, i) => (
				<li
					className="mb-2"
					style={{
						listStylePosition: 'inside',
					}}
				>
					{k}
				</li>
			))
		}
	</ul>
);

DomainColumn.propTypes = {
	className: PropTypes.string,
	domain: PropTypes.string,
};
DomainColumn.defaultProps = {
	className: '',
	domain: '',
};
