import { useHistory } from 'react-router-dom';
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
} from 'reactstrap';

export const PageError403 = () => {
	const history = useHistory();
	return (
		<div className="flex-row align-items-center my-5">
			<Container>
				<Row className="justify-content-center">
					<Col md="6">
						<Card className="p-4">
							<CardBody>
								<h1>
									Accès refusé
								</h1>
								<p className="text-muted mb-4">
									Vous n&apos;avez pas les autorisations
									nécessaires pour effectuer cette action.
								</p>
								<Row>
									<Col className="text-center">
										<Button
											color="secondary"
											className="px-4"
											onClick={() => { history.push('/'); }}
										>
											Retour
										</Button>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
