import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

export const UsePagination = ({
	pagesCount,
	firstCurrentPage,
}) => {
	const [currentPage, setCurrentPage] = useState(firstCurrentPage);

	const handlePageClick = (_, i) => {
		setCurrentPage(i);
	};
	const handlePreviousClick = useCallback(() => {
		if (currentPage > 0) {
			setCurrentPage(currentPage - 1);
		}
	}, [currentPage]);
	const handleNextClick = useCallback(() => {
		if (currentPage < pagesCount - 1) {
			setCurrentPage(currentPage + 1);
		}
	}, [currentPage, pagesCount]);

	const resetCurrentPage = useCallback(() => {
		setCurrentPage(firstCurrentPage);
	}, [firstCurrentPage]);
	return {
		currentPage,
		handlePageClick,
		handlePreviousClick,
		handleNextClick,
		resetCurrentPage,
	};
};
UsePagination.propTypes = {
	pagesCount: PropTypes.number.isRequired,
	firstCurrentPage: PropTypes.number,
};

UsePagination.defaultProps = {
	firstCurrentPage: 0,
};
