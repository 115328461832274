import { Field, FieldError } from 'react-jsonschema-form-validation';
import PropTypes from 'prop-types';

import {
	FormGroup,
	Input,
	Label,
} from 'reactstrap';

export const ContactFormGroupUnit = ({ disabled, value }) => (
	<FormGroup key="unit">
		<Label className="mb-0 font-weight-bold">Unité</Label>
		<Field
			component={Input}
			disabled={disabled}
			placeholder="Unité (Gestion Crédits Tous Marchés, Collecte & Incidents ... )"
			name="unit"
			value={value}
		/>
		<FieldError name="unit" />
	</FormGroup>
);

ContactFormGroupUnit.propTypes = {
	disabled: PropTypes.bool,
	value: PropTypes.string.isRequired,
};

ContactFormGroupUnit.defaultProps = {
	disabled: false,
};
