import {
	useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import {
	Alert,
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Input,
	Label,
	Row,
} from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { searchPatternRegex } from 'api/schemas/search';

import { fetchBySearch } from '../api/contact';
import { SimpleTable } from '../components/SimpleTable';
import { ButtonLoader } from '../components/ButtonLoader';
import { TablePagination, UsePagination } from '../components/pagination';
import { clearAuth } from '../lib/authentication';

import './Search.scss';

const PARAM_SEARCH = 'search';
const PARAM_PAGE = 'page';

export const PageSearch = () => {
	useQueryClient();

	const useURLQuery = new URLSearchParams(useLocation().search);
	const searchURL = (useURLQuery.get(PARAM_SEARCH) || '').trimStart();
	const currentPageURL = Math.max(useURLQuery.get(PARAM_PAGE) || 1, 0);

	const tableRef = useRef();
	const history = useHistory();
	const location = useLocation();
	const [search, setSearch] = useState('');
	const [firstCurrentPage, setFirstCurrentPage] = useState(+currentPageURL - 1 || 0);
	const [searchValidationError, setSearchValidationError] = useState(null);
	const [data, setData] = useState(null);
	const [selected, setSelected] = useState(null);
	const [offsetY, setOffsetY] = useState(0);
	// Pagination
	const maxRows = 10;
	const pagesCount = useMemo(() => {
		const row = data?.length ? data.length : 0;
		return Math.ceil(row / maxRows);
	}, [data]);
	const {
		currentPage,
		handlePageClick,
		handlePreviousClick,
		handleNextClick,
		resetCurrentPage,
	} = UsePagination({
		pagesCount,
		firstCurrentPage,
	});
	const dataPaginate = useMemo(() => (
		data?.slice(currentPage * maxRows, currentPage * maxRows + maxRows)
	), [currentPage, data]);
	// use query mutation
	const {
		mutate: mutateSearch,
		isSuccess,
		error: searchError,
		isLoading: searchLoading,
	} = useMutation(fetchBySearch, {
		onSuccess: (newData) => {
			if (search !== '') {
				setData(newData);
			}
		},
		onError: (error) => {
			if (error.response.status === 401) {
				clearAuth();
				history.push('/login');
			}
		},
	});

	const setNewValueToSearch = useCallback((value) => {
		if (value !== '' && searchPatternRegex.test(value.trim()) === false) {
			setSearchValidationError(true);
		} else {
			setSearchValidationError(false);
		}
		setSearch(value.trimStart());
	}, []);

	const handleChange = useCallback((newData) => {
		const { value } = newData.target;
		setSelected(false);
		resetCurrentPage();
		setNewValueToSearch(value);
	}, [resetCurrentPage, setNewValueToSearch]);

	const handleSelectOne = (d, e) => {
		const elem = e.target;
		const rect = elem.getBoundingClientRect();
		setSelected(d);
		setOffsetY(rect.top - tableRef.current.getBoundingClientRect().top);
	};

	const updateSearchURL = useCallback((searchText) => {
		history.push(`${location.pathname}?${PARAM_SEARCH}=${searchText}&${PARAM_PAGE}=${currentPage + 1}`);
	}, [currentPage, history, location.pathname]);

	const handleSearch = () => {
		//mutateSearch(search);
		updateSearchURL(search);
	};

	const handleKeyUp = (e) => {
		if (e.key === 'Enter') {
			handleSearch();
		}
	};
	// URL
	// useEffect(() => {
	// 	updateSearchURL(search);
	// }, [search, updateSearchURL]);

	useEffect(() => {
		// Set only one time
		setNewValueToSearch(searchURL || '');
		setFirstCurrentPage(0);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (search === '') {
			setData(null);
		}
	}, [search]);

	useEffect(() => {
		if (searchURL !== '' && searchValidationError === false) {
			mutateSearch(searchURL);
		}
	}, [mutateSearch, searchURL, searchValidationError]);

	useEffect(() => {
		if (currentPage !== 0) {
			updateSearchURL(search);
		}
	}, [currentPage, updateSearchURL, search]);

	return (
		<Container className="Search pt-4" fluid>
			<h1 className="mb-4 text-center">Rechercher un contact</h1>
			<Row className="justify-content-center">
				<Col
					className="d-flex flex-column align-items-center justify-content-center"
					xs="12"
					md="8"
					lg="6"
				>
					<div className="w-100 h-100 d-flex">
						<Input
							autoFocus
							name="search"
							placeholder="Saisissez votre recherche : épargne, offre de prêt ... "
							value={search}
							onChange={handleChange}
							onKeyUp={handleKeyUp}
						/>
						<ButtonLoader
							className="ml-2"
							color="danger"
							disabled={!!searchValidationError}
							loading={searchLoading}
							onClick={handleSearch}
						>
							<span>
								Rechercher
							</span>
						</ButtonLoader>
					</div>
					{ searchValidationError === true && (
						<p className="SearchInfo mb-0">
							<small className="text-danger line-height-1">
								<strong>Saisie invalide</strong> <br />
								Les caractères autorisés sont les lettres (a..z et A..Z),
								les chiffres, les parenthèses et le point.
							</small>
						</p>
					)}
					{!searchValidationError && isSuccess && data?.length > 1 && (
						<p className="SearchInfo mb-0"><small><strong>{data.length}</strong> contacts trouvés</small></p>
					)}
					{!searchValidationError && isSuccess && data?.length === 0 && (
						<p className="SearchInfo mb-0">
							<small className="mt-2">
								Aucun contact trouvé
							</small>
						</p>
					)}
					{searchError && (
						<Alert color="danger" className="mt-2">
							{searchError?.response?.status === 422 ? 'Recherche non valide' : searchError?.message}
						</Alert>
					)}
				</Col>
			</Row>
			{/* {search === '' && (
				<div className="w-100 d-flex justify-content-center mt-4" xs="12">
					<span className="h4">
						Veuillez rentrer au moins un caractère afin de visualiser votre recherche !
					</span>
				</div>
			)} */}
			{ searchLoading && (
				<Row className="mt-4">
					<Col className="d-flex align-center justify-content-center">
						<Icon icon={faSpinner} spin className="spinner" />
					</Col>
				</Row>
			)}
			{data?.length > 0 && isSuccess && !searchLoading && searchValidationError === false && (
				<Row className="mt-4">
					<Col xs={{ size: 12, order: 2 }} lg={{ size: 8, order: 1 }} xl={{ size: 9, order: 1 }}>
						<div ref={tableRef}>
							<SimpleTable onClickCell={handleSelectOne} columns={['domain', 'quoi', 'email', 'qui', 'comments']} data={dataPaginate} />
						</div>
						<div className="d-flex justify-content-center">
							<TablePagination
								pagesCount={pagesCount}
								currentPage={currentPage}
								handlePageClick={handlePageClick}
								handlePreviousClick={handlePreviousClick}
								handleNextClick={handleNextClick}
							/>
						</div>
					</Col>
					{selected
					&& (
						<Col className="mb-4" xs={{ size: 12, order: 1 }} lg={{ size: 4, order: 2 }} xl={{ size: 3, order: 2 }}>
							<div
								className="d-md-none"
								style={{
									position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: '#666', opacity: 0.45,
								}}
							/>
							<Card
								style={{
									position: 'absolute',
									left: 0,
									right: 0,
									top: offsetY,
								}}
							>
								<CardBody className="d-flex flex-column w-100 align-items-center justify-content-center">
									{
										(!selected.board && !selected.unit && !selected.activityManager) && (
											<strong>
												N.C.
											</strong>
										)
									}
									{
										selected.board && (
											<div className="mb-2 d-flex flex-column justify-content-center align-items-center">
												<Label style={{ lineHeight: 1 }} className="m-0 text-uppercase font-size-80 font-weight-bold">
													Direction / Filiale
												</Label>
												{selected.board}
											</div>
										)
									}
									{
										selected.unit && (
											<div className="mb-2 d-flex flex-column justify-content-center align-items-center">
												<Label style={{ lineHeight: 1 }} className="m-0 text-uppercase font-size-80 font-weight-bold">
													Unité
												</Label>
												{selected.unit}
											</div>
										)
									}
									{
										selected.activityManager && (
											<div className="mb-2 d-flex flex-column justify-content-center align-items-center">
												<Label style={{ lineHeight: 1 }} className="m-0 text-uppercase font-size-80 font-weight-bold">
													Responsable d&apos;unité
												</Label>
												{selected.activityManager}
											</div>
										)
									}
									<Button
										color="transparent"
										style={{
											position: 'absolute',
											top: 0,
											right: 0,
											color: 'inherit',
										}}
										onClick={() => setSelected(null)}
									>
										<i
											className="fa fa-times"
										/>
									</Button>
								</CardBody>
							</Card>
						</Col>
					)}
				</Row>
			)}
		</Container>
	);
};
