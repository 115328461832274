import { memo } from 'react';
import PropTypes from 'prop-types';

export const Image = memo(({
	alt,
	src,
	webp,
	...props
}) => (
	<picture>
		{ webp && <source srcSet={webp} type="image/webp" /> }
		<img src={src} alt={alt} {...props} />
	</picture>
));

Image.propTypes = {
	alt: PropTypes.string.isRequired,
	src: PropTypes.string.isRequired,
	webp: PropTypes.string,
};

Image.defaultProps = {
	webp: null,
};
