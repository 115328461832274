export const SelectStyle = {
	//...styles,
	control: (base, state) => ({
		...base,
		//'&:hover': { borderColor: '#e4e7ea' }, // border style on hover
		//'&:focus': { borderColor: '#707e8a' }, // border style on hover
		//'&:active': { borderColor: '#e4e7ea' }, // border style on hover
		//border: '1px solid #e4e7ea', // default border color
		boxShadow: 'none', // no box-shadow
		borderColor: state.isFocused
			? '#707e8a'
			: '#e4e7ea',
		'&:hover': {
			borderColor: state.isFocused
				? '#707e8a'
				: '#e4e7ea',
		},
	}),
};
