import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export const TablePagination = ({
	pagesCount,
	currentPage,
	handlePageClick,
	handlePreviousClick,
	handleNextClick,
}) => {
	const maxP = 16;
	const maxL = Math.min(maxP, Math.max(maxP - (pagesCount - currentPage), maxP / 2));
	const maxR = Math.min(maxP, Math.max(maxP - currentPage, maxP / 2));
	const arrPages = [...Array(pagesCount).keys()].slice(
		Math.max(0, currentPage - maxL),
		Math.min(pagesCount, currentPage + maxR),
	);
	return (
		<Pagination>
			<PaginationItem disabled={currentPage <= 0}>
				<PaginationLink onClick={handlePreviousClick} first />
			</PaginationItem>
			{arrPages.map((page, i) => {
				if (i === 0 && currentPage - maxL > 0) {
					return (
						<PaginationItem active={page === currentPage} key={page}>
							<PaginationLink onClick={(e) => handlePageClick(e, 1)}>
								1
							</PaginationLink>
						</PaginationItem>
					);
				}
				if (i === 1 && currentPage - maxL > 0) {
					return (
						<PaginationItem disabled key={page}>
							<PaginationLink>
								...
							</PaginationLink>
						</PaginationItem>
					);
				}
				if (i === arrPages.length - 1 && currentPage + maxR < pagesCount) {
					return (
						<PaginationItem active={page === currentPage} key={page}>
							<PaginationLink onClick={(e) => handlePageClick(e, pagesCount - 1)}>
								{pagesCount}
							</PaginationLink>
						</PaginationItem>
					);
				}
				if (i === arrPages.length - 2 && currentPage + maxR < pagesCount) {
					return (
						<PaginationItem disabled key={page}>
							<PaginationLink>
								...
							</PaginationLink>
						</PaginationItem>
					);
				}
				return (
					<PaginationItem active={page === currentPage} key={page}>
						<PaginationLink onClick={(e) => handlePageClick(e, page)}>
							{page + 1}
						</PaginationLink>
					</PaginationItem>
				);
			})}
			<PaginationItem disabled={currentPage >= pagesCount - 1}>
				<PaginationLink onClick={handleNextClick} last />
			</PaginationItem>
		</Pagination>
	);
};
TablePagination.propTypes = {
	pagesCount: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,
	handlePageClick: PropTypes.func.isRequired,
	handlePreviousClick: PropTypes.func.isRequired,
	handleNextClick: PropTypes.func.isRequired,
};
