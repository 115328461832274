const contactSchema = require('../../schemas/contact');
const { getSelectedPropertiesFromSchema } = require('../utils');

const contactValidatorCreate = {
	type: 'object',
	additionalProperties: false,
	required: [
		'quoi', 'domain',
	],
	properties: getSelectedPropertiesFromSchema(contactSchema, [
		'activityManager',
		'board',
		'comments',
		'domain',
		'email',
		'qui',
		'quoi',
		'unit',
	]),
};

const contactValidatorUpdate = {
	type: 'object',
	additionalProperties: false,
	required: [
		...contactValidatorCreate.required,
		'id',
	],
	properties: {
		...contactValidatorCreate.properties,
		id: contactSchema.properties.id,
	},
};

exports.contactValidatorCreate = contactValidatorCreate;
exports.contactValidatorUpdate = contactValidatorUpdate;
