import { Field, FieldError } from 'react-jsonschema-form-validation';
import PropTypes from 'prop-types';

import {
	FormGroup,
	Label,
} from 'reactstrap';

import { QuoiTags } from '../../QuoiTags';

export const ContactFormGroupQuoi = ({ disabled, value, handleTagInputChange }) => (
	<FormGroup key="quoi">
		<Label className="mb-0 font-weight-bold">Quoi *</Label><br />
		<small className="text-muted">
			Saisissez un mot-clé et appuyez sur la touche entrée pour l&apos;ajouter
		</small>
		<Field
			component={QuoiTags}
			disabled={disabled}
			handleTagInputChange={handleTagInputChange}
			name="quoi"
			value={value}
		/>
		<FieldError name="quoi" />
	</FormGroup>
);

ContactFormGroupQuoi.propTypes = {
	disabled: PropTypes.bool,
	handleTagInputChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
};

ContactFormGroupQuoi.defaultProps = {
	disabled: false,
};
