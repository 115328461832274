import { Field, FieldError } from 'react-jsonschema-form-validation';
import PropTypes from 'prop-types';

import {
	FormGroup,
	Input,
	Label,
} from 'reactstrap';

export const ContactFormGroupQui = ({ disabled, value }) => (
	<FormGroup key="qui">
		<Label className="mb-0 font-weight-bold">Qui</Label>
		<Field
			component={Input}
			disabled={disabled}
			name="qui"
			placeholder="Qui (téléphone)"
			value={value}
		/>
		<FieldError name="qui" />
	</FormGroup>
);

ContactFormGroupQui.propTypes = {
	disabled: PropTypes.bool,
	value: PropTypes.string.isRequired,
};

ContactFormGroupQui.defaultProps = {
	disabled: false,
};
