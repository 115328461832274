import PropTypes from 'prop-types';

export const QuoiColumn = ({ className, quoi }) => (
	<ul className={className}>
		{
			quoi.split('/').map((k) => (
				<li
					style={{
						listStylePosition: 'inside',
					}}
					key={k}
				>
					{k}
				</li>
			))
		}
	</ul>
);

QuoiColumn.propTypes = {
	className: PropTypes.string,
	quoi: PropTypes.string,
};
QuoiColumn.defaultProps = {
	className: '',
	quoi: '',
};
