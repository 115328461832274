import {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
	CustomInput,
	FormGroup,
	Input,
	Label,
} from 'reactstrap';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';

import { SelectRole } from '../select/Role';
import { ButtonLoader } from '../ButtonLoader';
import { enumRole } from '../../lib/enumRole';

const schema = (changePassword) => ({
	type: 'object',
	properties: {
		password: { type: 'string' },
		changePassword: { type: 'boolean' },
		role: { type: 'string', enums: Object.keys(enumRole) },
	},
	required: changePassword ? ['role', 'password', 'changePassword'] : ['role', 'changePassword'],
});

const initData = {
	password: '',
	role: '',
	changePassword: false,
};
export const UpdateUser = ({
	user,
	mutateUser,
}) => {
	const [formData, setFormData] = useState(initData);
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		setFormData({ ...initData, role: user.role });
	}, [user]);

	const handleChange = (newData) => {
		setFormData(newData);
	};

	const handleSelectRoleChange = (role) => {
		setFormData((prev) => ({ ...prev, role: role.value }));
	};

	const handleSubmit = useCallback(async () => {
		setIsLoading(true);
		try {
			let bodyData = {
				role: formData.role,
			};
			if (formData.changePassword) {
				bodyData = { ...bodyData, password: formData.password };
			}
			await mutateUser({ id: user.id, data: bodyData, username: user.username });
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e);
			throw e;
		}
	}, [formData, mutateUser, user]);

	const dynamicSchema = useMemo(() => schema(formData.changePassword), [formData]);
	return (
		<Form
			className="	"
			data={formData}
			onChange={handleChange}
			onSubmit={handleSubmit}
			schema={dynamicSchema}
		>
			<div className="">
				<Field
					component={CustomInput}
					name="changePassword"
					type="checkbox"
					label="Changer le mot de passe"
					id="changePassword"
					value={formData.changePassword ? 'checked' : ''}
				/>
				<FormGroup className="">
					<Label className="mb-0">
						Mot de passe
					</Label>
					<Field
						component={Input}
						disabled={formData.changePassword === false}
						id="password"
						name="password"
						placeholder="Mot de passe"
						type="password"
						value={formData.password}
					/>
					<FieldError name="password">
						Merci de renseigner le mot de passe.
					</FieldError>
				</FormGroup>
			</div>
			<FormGroup className="">
				<Label className="mb-0">
					Rôle
				</Label>
				<SelectRole
					onChange={handleSelectRoleChange}
					value={formData.role}
				/>
				<FieldError name="role">
					Merci de selectionner un rôle.
				</FieldError>
			</FormGroup>
			<FormGroup className="">
				<ButtonLoader
					type="submit"
					block
					color="secondary"
					loading={isLoading}
				>
					Modifier
				</ButtonLoader>
			</FormGroup>
		</Form>
	);
};

UpdateUser.propTypes = {
	user: PropTypes.shape().isRequired,
	mutateUser: PropTypes.func.isRequired,
};
