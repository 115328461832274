import PropTypes from 'prop-types';
import Select from 'react-select';
import { enumRole } from '../../lib/enumRole';
import { translate } from '../../lib/translate';
import { SelectStyle } from './Style';

const options = Object.keys(enumRole).map((r) => (
	{
		label: translate[r] || r,
		value: r,
	}));

export const SelectRole = ({
	value,
	onChange,
	...props
}) => (
	<Select
		options={options}
		onChange={onChange}
		name="role"
		placeholder="Rôle"
		styles={SelectStyle}
		//getOptionLabel={(option) => !console.log('la', option) && option.label}
		//getOptionValue={(option) => !console.log(option) && option.value}
		value={options.find((obj) => obj.value === value) || null}
		{...props}
	/>
);

SelectRole.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};
