import {
	useCallback, useState,
} from 'react';
import {
	Alert,
	FormGroup,
	Input,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Col,
} from 'reactstrap';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';
import { useMutation, useQuery } from 'react-query';

import { SelectRole } from '../select/Role';
import { createUser, fetchAllUser } from '../../api/user';
import { errMessage } from '../../lib/messages';
import { ButtonLoader } from '../ButtonLoader';
import { enumRole } from '../../lib/enumRole';

const schema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		username: { type: 'string' },
		password: { type: 'string' },
		role: { type: 'string', enums: Object.keys(enumRole) },
	},
	required: ['username', 'password', 'role'],
};

const initData = {
	username: '',
	password: '',
	role: '',
};
export const CreateUser = () => {
	const { refetch } = useQuery('users', fetchAllUser);
	const [formData, setFormData] = useState(initData);
	const {
		mutate: mutateUser,
		isSuccess,
		isError,
		isLoading,
		error,
		data,
	} = useMutation(createUser, {
		onSuccess: () => {
			setFormData(initData);
			refetch();
		},
	});

	const handleChange = (newData) => {
		setFormData(newData);
	};

	const handleSelectRoleChange = (role) => {
		setFormData((prev) => ({ ...prev, role: role.value }));
	};

	const handleSubmit = useCallback(async () => {
		mutateUser(formData);
	}, [formData, mutateUser]);

	return (
		<Card>
			<Form
				data={formData}
				onChange={handleChange}
				onSubmit={handleSubmit}
				schema={schema}
			>
				<CardHeader>
					<h2 className="m-0 text-dark text-uppercase">
						Ajouter un utilisateur
					</h2>
				</CardHeader>
				<CardBody>
					<Col xs="auto">
						{isSuccess && <Alert color="success">L&apos;utilisateur {data.username} a bien été créé</Alert>}
						{isError && <Alert color="danger">{errMessage(error)}</Alert>}
						<FormGroup className="">
							<Field
								component={Input}
								name="username"
								placeholder="Identifiant"
								id="username"
								value={formData.username}
							/>
							<FieldError name="username">
								Merci de renseigner un identifiant.
							</FieldError>
						</FormGroup>
						<FormGroup className="">
							<Field
								component={Input}
								name="password"
								placeholder="Mot de passe"
								id="password"
								value={formData.password}
							/>
							<FieldError name="password">
								Merci de renseigner un mot de passe.
							</FieldError>
						</FormGroup>
						<FormGroup className="">
							<SelectRole
								onChange={handleSelectRoleChange}
								clearable
								value={formData.role}
							/>
							<FieldError name="role">
								Merci de selectionner un rôle.
							</FieldError>
						</FormGroup>
					</Col>
				</CardBody>
				<CardFooter className="d-flex align-items-center justify-content-end">
					<ButtonLoader
						className="btn-block w-auto"
						color="cecaz"
						loading={isLoading}
						type="submit"
					>
						Valider
					</ButtonLoader>
				</CardFooter>
			</Form>

		</Card>
	);
};
