import {
	Row,
	Col,
	Container,
} from 'reactstrap';

import { ListUser } from '../components/user/ListUser';
import { CreateUser } from '../components/user/CreateUser';

export const PageUsers = () => (
	<Container className="pt-4">
		<h1 className="mb-4 text-center">Utilisateurs</h1>
		<Row className="justify-content-around align-items-start mb-5">
			<Col className="mb-5" xs="12">
				<CreateUser />
			</Col>
			<Col className="" xs="12">
				<ListUser />
			</Col>
		</Row>
	</Container>
);
