import { Field, FieldError } from 'react-jsonschema-form-validation';
import PropTypes from 'prop-types';

import {
	FormGroup,
	Input,
	Label,
} from 'reactstrap';

export const ContactFormGroupComment = ({ disabled, value }) => (
	<FormGroup key="comments">
		<Label className="mb-0 font-weight-bold">Commentaires</Label>
		<Field
			component={Input}
			disabled={disabled}
			placeholder="Commentaire"
			name="comments"
			value={value}
		/>
		<FieldError name="comments" />
	</FormGroup>
);

ContactFormGroupComment.propTypes = {
	disabled: PropTypes.bool,
	value: PropTypes.string.isRequired,
};

ContactFormGroupComment.defaultProps = {
	disabled: false,
};
