import { useState } from 'react';
import Form from 'react-jsonschema-form-validation';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Container,
	Row,
	UncontrolledAlert,
} from 'reactstrap';
import { contactValidatorCreate } from 'api/lib/validators/contact';

import {
	contactCreate,
} from '../api/contact';
import { ButtonLoader } from '../components/ButtonLoader';

import {
	ContactFormGroupActivityManager,
	ContactFormGroupBoard,
	ContactFormGroupComment,
	ContactFormGroupDomain,
	ContactFormGroupQui,
	ContactFormGroupQuoi,
	ContactFormGroupUnit,
} from '../components/contact/formGroup';
import { ContactFormGroupEmail } from '../components/contact/formGroup/Email';

import errorMessages from '../lib/Form/errorMessages';

import './Contact.scss';

const schema = contactValidatorCreate;

const initState = {
	domain: '',
	board: '',
	unit: '',
	activityManager: '',
	qui: '',
	email: '',
	quoi: '',
	comments: '',
};
export const CREATE_ACTION = 'create';

export const PageContactCreate = () => {
	const history = useHistory();

	const [data, setData] = useState(initState);
	const {
		mutate: mutateCreate, isSuccess: isSuccessCreate, isLoading: isLoadingCreate,
	} = useMutation(contactCreate, {
		onSuccess: ({ id }) => {
			history.push({
				pathname: `/contact/${id}`,
				state: { action: CREATE_ACTION },
			});
		},
	});

	const handleChange = (newData) => {
		setData(newData);
	};

	const handleSubmit = () => {
		mutateCreate(data);
	};

	const handleTagInputChange = (newValue, name) => {
		setData((prev) => ({
			...prev,
			[name]: newValue.join('/'),
		}));
	};
	return (
		<Form
			className="Contact w-100 py-4"
			data={data}
			errorMessages={errorMessages}
			onChange={handleChange}
			onSubmit={handleSubmit}
			schema={schema}
		>
			<Container className="h-100">
				{isSuccessCreate && (
					<UncontrolledAlert className="w-100" color="success">
						Le contact a été créé avec succès.
					</UncontrolledAlert>
				)}
				<Row>
					<Card className="w-100">
						<CardHeader className="d-flex justify-content-between">
							<h1 className="mb-0 text-dark">
								Créer un nouveau contact
							</h1>
						</CardHeader>
						<CardBody>
							<ContactFormGroupDomain
								value={data.domain}
							/>
							<ContactFormGroupQuoi
								value={data.quoi}
								handleTagInputChange={handleTagInputChange}
							/>
							<ContactFormGroupEmail
								value={data.email}
							/>
							<ContactFormGroupQui
								value={data.qui}
							/>
							<ContactFormGroupComment
								value={data.comments}
							/>
							<ContactFormGroupBoard
								value={data.board}
							/>
							<ContactFormGroupUnit
								value={data.unit}
							/>
							<ContactFormGroupActivityManager
								value={data.activityManager}
							/>
						</CardBody>
						<CardFooter className="d-flex justify-content-end">
							<ButtonLoader color="cecaz" loading={isLoadingCreate} type="submit">
								Valider
							</ButtonLoader>
						</CardFooter>
					</Card>
				</Row>
			</Container>
		</Form>
	);
};
