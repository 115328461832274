module.exports = {
	type: 'object',
	additionalProperties: false,
	properties: {
		id: { type: 'integer' },
		activityManager: { type: 'string' },
		board: { type: 'string' },
		comments: { type: 'string' },
		deleted: { type: 'boolean' },
		domain: { type: 'string' },
		email: { type: 'string' },
		qui: { type: 'string' },
		quoi: { type: 'string' },
		sub: { type: 'string' },
		unit: { type: 'string' },
	},
	required: [
		'domain', 'quoi',
	],
};
