import {
	HashRouter,
} from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';

import {
	QueryClient,
	QueryClientProvider,
} from 'react-query';

import { UserContext } from './context/UserContext';

import '@fortawesome/fontawesome-free/css/all.css';
// import { library } from '@fortawesome/fontawesome-svg-core';

import { Routes } from './Routes';
import { ScrollToTop } from './components/ScrollToTop';

import 'react-jsonschema-form-validation/dist/react-jsonschema-form-validation.css';
import './App.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { getLocalStorageUser, updateLocalStorageUser } from './lib/localstorage/userStorage';

const { REACT_APP_MATOMO_URL, REACT_APP_MATOMO_SITE_ID } = process.env;

const matomoInstance = createInstance({
	urlBase: REACT_APP_MATOMO_URL,
	siteId: REACT_APP_MATOMO_SITE_ID,
	trackerName: 'matomo',
});
const queryClient = new QueryClient();

export const App = () => {
	const [user, setUser] = useState({});

	const updateUserContext = useCallback((newUser) => {
		const u = { role: newUser.role, username: newUser.username };
		setUser(u);
		updateLocalStorageUser(u);
	}, []);

	useEffect(() => {
		setUser(getLocalStorageUser());
	}, [setUser]);

	return (
		<MatomoProvider value={matomoInstance}>
			<UserContext.Provider value={{ user, updateContextUser: updateUserContext }}>
				<HashRouter>
					<ScrollToTop>
						<QueryClientProvider client={queryClient}>
							<Routes />
						</QueryClientProvider>
					</ScrollToTop>
				</HashRouter>
			</UserContext.Provider>
		</MatomoProvider>
	);
};
