import { getAuth } from '../lib/authentication';
import { request } from '../lib/request';

const { REACT_APP_API_URL } = process.env;

const USER_API_PATH = `${REACT_APP_API_URL}/user`;

export const fetchAllUser = () => request(USER_API_PATH, {
	headers: { qui: getAuth() },
});

export const createUser = (data) => request(USER_API_PATH, {
	method: 'POST',
	body: data,
	headers: { qui: getAuth() },
});

export const updateUser = ({ id, data }) => request(`${USER_API_PATH}/${id}`, {
	method: 'PUT',
	body: data,
	headers: { qui: getAuth() },
});

export const deleteUser = (id) => request(`${USER_API_PATH}/delete/${id}`, {
	method: 'POST',
	headers: { qui: getAuth() },
});
