/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// https://github.com/facebook/create-react-app/issues/9906#issuecomment-720905753
import React from 'react';
import ReactDOM from 'react-dom';
import Rollbar from 'rollbar';

import { App } from './App';
import reportWebVitals from './reportWebVitals';

const { REACT_APP_ROLLBAR_ACCESS_TOKEN, REACT_APP_VERSION } = process.env;

global.rollbar = new Rollbar({
	accessToken: REACT_APP_ROLLBAR_ACCESS_TOKEN,
	captureUncaught: true,
	captureUnhandledRejections: true,
	enabled: (process.env.NODE_ENV === 'production'),
	payload: {
		environment: process.env.NODE_ENV,
		client: {
			javascript: {
				source_map_enabled: true,
				code_version: REACT_APP_VERSION,
				guess_uncaught_frames: true,
			},
		},
	},
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const {
	REACT_APP_BUILD_ID,
} = process.env;

// eslint-disable-next-line no-console
console.info(`ℹ️ v${REACT_APP_VERSION}/${REACT_APP_BUILD_ID}`);
