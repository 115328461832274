import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import PropTypes from 'prop-types';
import { getAuth } from './authentication';
import { UserContext } from '../context/UserContext';

const PrivateRoute = ({
	component: Component,
	role,
	...rest
}) => {
	const { user } = useContext(UserContext);
	const auth = getAuth();
	if (!auth) return (<Redirect to={{ pathname: '/login' }} />);
	const isAllowed = role.includes(user.role);
	if (!user.role) {
		return null;
	}
	if (!isAllowed) {
		return (<Redirect to="/403" />);
	}
	return (
		<Route
			{...rest}
			render={(props) => (<Component {...props} />)}
		/>
	);
};

// PrivateRouteBorne.contextType = UserContext;

PrivateRoute.propTypes = {
	component: PropTypes.oneOfType([PropTypes.func, PropTypes.symbol]).isRequired,
	role: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PrivateRoute;
