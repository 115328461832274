import { Field, FieldError } from 'react-jsonschema-form-validation';
import PropTypes from 'prop-types';

import {
	FormGroup,
	Input,
	Label,
} from 'reactstrap';

export const ContactFormGroupActivityManager = ({ disabled, value }) => (
	<FormGroup key="activityManager">
		<Label className="mb-0 font-weight-bold">Responsable d&apos;unité</Label>
		<Field
			component={Input}
			disabled={disabled}
			placeholder="Responsable d'unité (prénom, nom)"
			name="activityManager"
			value={value}
		/>
		<FieldError name="activityManager" />
	</FormGroup>
);

ContactFormGroupActivityManager.propTypes = {
	disabled: PropTypes.bool,
	value: PropTypes.string.isRequired,
};

ContactFormGroupActivityManager.defaultProps = {
	disabled: false,
};
